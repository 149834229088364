import React from 'react'
import styled from 'styled-components'
import SoraLayout from '../components/AppLayout'
import Menu from '../components/Menu'
import { COLLAPSED_MENU_WIDTH, MENU_WIDTH } from '../constants/sizes'
import AccountContent from '../pages-components/account/AccountContent'


const Dashboard = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  return <SoraLayout>
    <DashboardWrapper >
      <Menu collapsed={collapsed} />
      <ContentWrapper collapsed={collapsed}>
        <AccountContent />
      </ContentWrapper>
    </DashboardWrapper>
  </SoraLayout>

}

const DashboardWrapper = styled.div`
  display: flex;
  max-height: 100vh;
  overflow: hidden;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${MENU_WIDTH}px);
  ${props => props.collapsed && `
    width: calc(100% - ${COLLAPSED_MENU_WIDTH}px);
  `}
`

export default Dashboard