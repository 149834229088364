import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getMe } from '../../services/callApi';
import { getNewAccessToken } from '../../http';
import { navigate } from "gatsby"



const AccountContent = () => {
  const [user, setUser] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getUserData = async () => {
      try {
        await getNewAccessToken()
          .catch(() => {
            navigate("/auth");
            return;
          });
        const me = await getMe();
        if (me.user.is_healthcare_clinician){
          setIsAuth(true);
          setUser({name: me.user.first_name + ' ' + me.user.last_name, email: me.user.email})
          setIsLoading(false)
        }
      } catch (error) {
        return;
      }
    };

    getUserData();
  }, []);

  return !isLoading ? <Wrapper>
    <ContentWrapper>
        <UserName>
          {user?.name}
        </UserName>
        <Label>
          Name
        </Label>
        <Value>
          {user?.name}
        </Value>
        <Label>
          Email
        </Label>
        <Value>
          {user?.email}
        </Value>
        <Label>
          Authenticated
        </Label>
        <Value>
          {isAuth ? "Yes" : "No"}
        </Value> 
    </ContentWrapper>
  </Wrapper>
  : <Wrapper> 
    Loading
  </Wrapper>
}

export default AccountContent

const Wrapper = styled.div`
flex: 1;
padding: 50px 70px;
padding-top: 20px;
background-color: #FBFBFB;
max-height: 100vh;
overflow: auto;
`

const ContentWrapper = styled.div`
  flex: 1;
  background: #fff;
  margin: 20px;
  padding: 40px;
  border-radius: 20px;
`
const UserName = styled.div`
  font-family: DM Serif Text;

  font-size: 32px;
  padding-bottom: 20px;
`

const Label = styled.div`
  font-family: NunitoSans-Bold;
  font-size: 22px;
  margin-top: 20px;
`

const Value = styled.div`
font-family: NunitoSans;
font-size: 22px;
margin-bottom: 20px;
`